module.exports = {
  //  testURL: 'http://localhost:8000',
   // preset: 'jest-puppeteer',
   
   // serverUrl : 'http://tserv01.exceloid.com:5001/',
   
  // serverUrl : 'http://195.201.88.218:5003/'
  // serverUrl : 'http://195.201.88.218:5008/'  // for new Air UI Application
   serverUrl : 'https://cwproject.mycw.in/'
      // serverUrl : 'http://localhost:5003/'
  };
  
  
    // "serverSideUrl":"http://tserv01.exceloid.com:3000/app"
  
   // Local system testing 
    // serverUrl : 'http://localhost:5001/'
