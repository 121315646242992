import axios from 'axios'

// import firebase from 'firebase/app'
import { notification } from 'antd'
import config from '../config'

// import 'firebase/auth'
// import 'firebase/database'
// import 'firebase/storage'

// const firebaseConfig = {
//   apiKey: 'AIzaSyA2LHKgdr2GQb_QUBYfhMOaxgOuGjw1z5E',
//   authDomain: 'airui-a4b63.firebaseapp.com',
//   databaseURL: 'https://airui-a4b63.firebaseio.com',
//   projectId: 'airui-a4b63',
//   storageBucket: 'airui-a4b63.appspot.com',
//   messagingSenderId: '1039460737420',
// }

// const firebaseApp = firebase.initializeApp(firebaseConfig)
// const firebaseAuth = firebase.auth
// export default firebaseApp

// export async function login(email, /* password */) {
//   return firebaseAuth()
//     .signInWithEmailAndPassword(email, /* password */)
//     .then(() => true)
//     .catch(error => {
//       notification.warning({
//         message: error.code,
//         description: error.message,
//       })
//     })
// }

// export async function currentAccount() {
//   let userLoaded = false
//   function getCurrentUser(auth) {
//     return new Promise((resolve, reject) => {
//       if (userLoaded) {
//         resolve(firebaseAuth.currentUser)
//       }
//       const unsubscribe = auth.onAuthStateChanged(user => {
//         userLoaded = true
//         unsubscribe()
//         resolve(user)
//       }, reject)
//     })
//   }
//   return getCurrentUser(firebaseAuth())
// }

// export async function logout() {
//   return firebaseAuth()
//     .signOut()
//     .then(() => true)
// }


// Login functionality 

export async function login(email,name,avatar /* password */) {
  window.localStorage.setItem('flag', false);
  window.localStorage.setItem('email','');
  let flag = false;
  console.log("data===>", email, /* password */)
  await axios({
    method: 'POST',
    url: config.serverUrl.concat("login"),
    data: { "email": email/* , "password": password  */}
  }).then((response) => {
    // console.log("response.data.",response.data)
    const userid = response.data.user.id;
    const  userstatus = response.data.user.status;
    // console.log("userstatys===>",userstatus)
    if (response.data.status === "Success") {
    //   const userid = response.data.user.id;
    // const  userstatus = response.data.user.status;
      flag = true;
      window.localStorage.setItem('flag', true);
      window.localStorage.setItem('email',email);
      window.localStorage.setItem('userid',userid)
      window.localStorage.setItem('status',userstatus)
      window.localStorage.setItem("name",name)
      window.localStorage.setItem("avatar",avatar)
    } else {
      console.log("inside else")
      notification.warning({
        message: "Error",
        description: "Invalid User",
      })
    }
  })
  return flag;
}

  export async function currentAccount() {
    const obj = {
      email:window.localStorage.getItem('email'),
      authorized:window.localStorage.getItem('flag'),
      userid:window.localStorage.getItem('userid'),
      name:window.localStorage.getItem('name'),
      avatar:window.localStorage.getItem('avatar'),
      userStatus:window.localStorage.getItem('status')
      // userstatus:window.localStorage.setItem('status')

    }
    return obj;
  }

export async function logout() {
  localStorage.removeItem('flag');
  localStorage.removeItem('status')
  return true
}