export  async function getMenuData() {
  return [
    // {
    //   title: 'My Tasks',
    //   key: 'mytasks',
    //   url: '/emp/mytasks',
    // },
    {
      title: 'My Activities',
      key: 'grid',
      url: '/emp/activities',
    },
    {
      title: 'My Projects',
      key: 'myprojects',
      url: '/emp/myprojects',
    },
    {
      title: 'Timesheet',
      key:'timesheet',
      url: '/emp/timesheet',
    },
    {
      title: 'Infra Request',
      key: 'infrarequest',
      url: '/emp/infrarequest/createinfrarequest',
    },
    {
      title:'Applications',
      key:'applications',
      url: '/ceo/modules',   
    },
  ]
}

export  async function getMenuDataforCeo() {
  return [
    // {
    //   title: 'Reports',
    //   key: 'reports',
    //   url: '/advanced/typography',
    // },
    {
      title: 'Alerts',
      key:'alerts',
      url: '/taskalerts/alerts',
    },
    {
      title: 'Activities',
      key: 'activities',
      url: '/ceo/activities',
    },
    {
      title:'Tasks',
      key:'tasks',
      url:'/ceo/tasks',
    },
    {
      title: 'Timesheet',
      key:'timesheet',
      url: '/emp/timesheet',
    },
    {
      title: 'My Activity',
      key:'ceoactivities',
      url: '/ceo/ceoactivities',
    },
    {
      title: 'Reports',
      key: 'appsCart',
      children:[
        {
          title:'Employee wise report',
          key: 'employeewisereport',
          url: '/ceo/employeewisereport',
        },
        {
          title:'Day wise report',
          key: 'daywisereport',
          url: '/ceo/daywisereport',
        },
        {
          title:'Client wise summary',
          key: 'clietwisesummary',
          url: '/ceo/clientwisesummary',
        },
        {
          title:'Activity Pivot Report',
          key: 'activitypivotreport',
          url: '/ceo/activitypivotreport',
        }
      ]
    },
    {
      title:'Configuration',
      key:'configuration',
      children: [
        {
          title:'Projects',
          key:'projects',
          url: '/ceo/projects',
    
        },
        {
          title:'Applications',
          key:'applications',
          url: '/ceo/modules',   
        },
        {
          title:'Servers',
          key:'servers',
          url: '/ceo/servers',
        },
        {
          title: 'Users',
          key: 'users',
          url: '/ceo/users',
        },
      ]
    }, 
    
      
  ]
}

export  async function getMenuDataforManagers() {
  return [
    {
      title: 'Alerts',
      key:'alerts',
      url: '/taskalerts/alerts',
    },
    {
      title: 'Timesheet',
      key:'timesheet',
      url: '/emp/timesheet',
    },
    {
      title: 'My Activities',
      key:'myactivitiy',
      url: '/manager/myactivities',
    },
    {
      title: 'Team Activities',
      key: 'teamactivities',
      url: '/manager/teamactivities',
    },
    {
      title: 'Team Projects',
      key: 'teamprojects',
      url: '/manager/teamprojects',
    },
    {
      title: 'Infra Request',
      key: 'infrarequest',
      url: '/emp/infrarequest/createinfrarequest',
    },
    {
      title:'Applications',
      key:'applications',
      url: '/ceo/modules',   
    },
    {
      title: 'Reports',
      key: 'appsCart',
      children:[
        {
          title:'Employee wise report',
          key: 'employeewisereport',
          url: '/ceo/employeewisereport',
        },
        {
          title:'Day wise report',
          key: 'daywisereport',
          url: '/ceo/daywisereport',
        },
        {
          title:'Client wise summary',
          key: 'clietwisesummary',
          url: '/ceo/clientwisesummary',
        },
        {
          title:'Activity Pivot Report',
          key: 'activitypivotreport',
          url: '/ceo/activitypivotreport',
        }
      ]
    },
  ]
}
export  async function getMenuDataforinfraMembers() {
  return [
    {
      title: 'My Activities',
      key: 'grid',
      url: '/emp/activities',
    },
    {
      title: 'My Projects',
      key: 'myprojects',
      url: '/emp/myprojects',
    },
    {
      title: 'Timesheet',
      key:'timesheet',
      url: '/emp/timesheet',
    },
    {
      title:'Servers',
      key:'servers',
      url: '/ceo/servers',
    },
    {
      title:'Infra Admin',
      key:'infraadmin',
      url:'emp/infraadmin'
    },
    {
      title:'Applications',
      key:'applications',
      url: '/ceo/modules',   
    },
  ]
}

export  async function getMenuDataforHR() {
  return [
    {
      title: 'My Activities',
      key: 'grid',
      url: '/emp/activities',
    },
    {
      title: 'My Projects',
      key: 'myprojects',
      url: '/emp/myprojects',
    },
    {
      title: 'Timesheet',
      key:'timesheet',
      url: '/emp/timesheet',
    },
    {
      title:'Applications',
      key:'applications',
      url: '/ceo/modules',   
    },
    {
      title:'Projects',
      key:'projects',
      url: '/ceo/projects',
    },
    {
      title: 'Reports',
      key: 'appsCart',
      children:[
        {
          title:'Employee wise report',
          key: 'employeewisereport',
          url: '/ceo/employeewisereport',
        },
        {
          title:'Day wise report',
          key: 'daywisereport',
          url: '/ceo/daywisereport',
        },
        {
          title:'Client wise summary',
          key: 'clietwisesummary',
          url: '/ceo/clientwisesummary',
        },
        {
          title:'Activity Pivot Report',
          key: 'activitypivotreport',
          url: '/ceo/activitypivotreport',
        }
      ]
    },
    {
      title: 'Users',
      key: 'users',
      url: '/ceo/users',
    },
  ]
}
