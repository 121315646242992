import { all, put, call } from 'redux-saga/effects'
import {getMenuData,getMenuDataforCeo,getMenuDataforManagers,getMenuDataforinfraMembers,getMenuDataforHR} from 'services/menu'
// import getMenuDataforCeo from 'services/menu'

export function* GET_DATA() {
  // let menuData = yield call(getMenuData)
  const userRole =localStorage.getItem('status')
  const userid =localStorage.getItem('userid')

  console.log("useroletype===>>>",userid,userRole)
if(userRole==='0'){
// console.log("MenuData in if============>",menuData)
const  menuData= yield call(getMenuData)
console.log("MenuData============>",menuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}
else if(userRole==='1'){
  const menuData = yield call(getMenuDataforCeo)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}else if(userRole==='2'){
  const menuData = yield call(getMenuDataforManagers)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}
else if(userRole==='3'){
  const menuData = yield call(getMenuDataforinfraMembers)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}
else if(userRole==='4'){
  const menuData = yield call(getMenuDataforHR)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
